import { forwardRef } from 'react'

import { cn } from '../utils/class-names.js'

const Input = forwardRef(
  function Input ({ label, className, type = 'text', disabled, ...rest }, ref) {
    return (
      <label className={cn('block w-64 space-y-1', className)}>
        {label && (
          <span className='block text-sm font-medium capitalize'>
            {label}
          </span>
        )}
        <input
          {...rest}
          ref={ref}
          type={type}
          disabled={disabled}
          className={cn('w-full px-3 py-2 text-sm border-transparent rounded-md shadow ring-1 ring-primary/50 text-primary placeholder:text-primary-muted disabled:bg-primary-100 disabled:cursor-not-allowed', className)}
        />
      </label>
    )
  }
)

export default Input
